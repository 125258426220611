import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ShowInsightsComponent } from './show-insights/show-insights.component';
import { InsightCardComponent } from './show-insights/insight-card/insight-card.component';
import { InsightDialogComponent } from './show-insights/insight-dialog/insight-dialog.component';
import { InsightsListComponent } from './show-insights/insights-list/insights-list.component';

const routes: Routes = [
  { path: 'display/insights', component: ShowInsightsComponent }
];

@NgModule({
  entryComponents: [InsightDialogComponent],
  imports: [CommonModule, RouterModule.forChild(routes), SharedModule],
  declarations: [
    ShowInsightsComponent,
    InsightCardComponent,
    InsightDialogComponent,
    InsightsListComponent
  ]
})
export class InsightsModule {}
