import { Component, OnInit } from '@angular/core';
import {
  InsightsQuery,
  InsightsService
} from '../../../shared/state/insights/index';

@Component({
  selector: 'kxl-list-insights',
  templateUrl: './list-insights.component.html',
  styleUrls: ['./list-insights.component.scss']
})
export class ListInsightsComponent implements OnInit {
  public insights$;
  public columns;

  constructor(private insightsQuery: InsightsQuery, service: InsightsService) {}

  ngOnInit() {
    this.insights$ = this.insightsQuery.selectAll();
    this.columns = ['title'];
  }
}
