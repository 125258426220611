import { Injectable } from '@angular/core';
import { TagCategoriesStore } from './tag-categories.store';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { createTagCategory, TagCategory } from './tag-category.model';
import { TagCategoriesQuery } from './tag-categories.query';

@Injectable({ providedIn: 'root' })
export class TagCategoriesService {
  private categoriesCollection: AngularFirestoreCollection;

  constructor(
    private tagCategoriesStore: TagCategoriesStore,
    private query: TagCategoriesQuery,
    private db: AngularFirestore
  ) {
    this.categoriesCollection = this.db.collection('tagCategories');
    this.get();
  }

  get() {
    this.categoriesCollection
      .valueChanges()
      .subscribe((categories: TagCategory[]) => {
        this.tagCategoriesStore.set(categories);
      });
  }

  add() {
    const id = this.db.createId();
    const category = createTagCategory('New Tag category');
    category.id = id;
    this.categoriesCollection
      .doc(id)
      .set(category)
      .then(() => {
        this.tagCategoriesStore.add(category);
      });
  }

  update(id, data: Partial<TagCategory>) {
    const category = this.query.getEntity(id);
    const update = {
      ...category,
      ...data
    };
    this.categoriesCollection
      .doc(id)
      .set(update)
      .then(() => {
        this.tagCategoriesStore.update(id, update);
      });
  }
}
