import { Component, OnInit } from '@angular/core';
import {
  Insight,
  InsightsQuery,
  InsightsService
} from '../../../shared/state/insights';
import { MatDialog, MatSelectionListChange } from '@angular/material';
import { InsightDialogComponent } from './insight-dialog/insight-dialog.component';
import {
  TagCategoriesQuery,
  TagCategoriesService
} from '../../../shared/state/tagCategories';
import { ProjectsQuery, ProjectsService } from '../../../shared/state/projects';
import { TagsService } from '../../../shared/state/tags';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, startWith, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import * as levenshtein from 'fast-levenshtein';

@Component({
  selector: 'kxl-show-insights',
  templateUrl: './show-insights.component.html',
  styleUrls: ['./show-insights.component.scss']
})
export class ShowInsightsComponent implements OnInit {
  public insights$;
  public loading$;
  public projects$;
  public categories$;
  public filteredInsights$;
  public searchCtrl = new FormControl('');

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe('(max-width: 768px)')
    .pipe(map(result => result.matches));

  selectedProjects$ = new BehaviorSubject([]);
  selectedTags$ = new BehaviorSubject({});

  constructor(
    private insightsQuery: InsightsQuery,
    private projectsQuery: ProjectsQuery,
    private categoriesQuery: TagCategoriesQuery,
    insightsService: InsightsService,
    categoriesService: TagCategoriesService,
    projectsService: ProjectsService,
    tagsService: TagsService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.insights$ = this.insightsQuery.selectAllWithData({});
    this.loading$ = this.insightsQuery.selectAllLoading();
    this.projects$ = this.projectsQuery.selectAll();
    this.categories$ = this.categoriesQuery
      .selectAllWithTags({})
      .pipe(tap(console.log));
    this.filteredInsights$ = combineLatest(
      this.searchCtrl.valueChanges.pipe(startWith('')),
      this.selectedTags$,
      this.selectedProjects$,
      this.insights$
    ).pipe(
      map(this.filterByTags),
      map(this.filterByProject),
      map(this.sortInsights)
    );
  }

  openInsight(insight) {
    this.dialog.open(InsightDialogComponent, {
      data: insight,
      maxWidth: '70vw'
    });
  }

  projectSelectionChange(change: MatSelectionListChange) {
    this.selectedProjects$.next(
      change.source.selectedOptions.selected.map(o => o.value)
    );
  }

  tagSelectionChange(change: MatSelectionListChange, category: string) {
    const currentTags = this.selectedTags$.value;
    const update = {};
    update[category] = change.source.selectedOptions.selected.map(o => o.value);
    this.selectedTags$.next({ ...currentTags, ...update });
  }

  private filterByProject([value, projects, insights]) {
    if (projects.length === 0) {
      return [value, insights];
    }
    const filteredInsights = insights.filter(i =>
      projects.includes(i.project_id)
    );
    return [value, filteredInsights];
  }

  private filterByTags([value, tags, projects, insights]) {
    const filteredCategories = Object.keys(tags).filter(t => tags[t].length);
    if (filteredCategories.length === 0) {
      return [value, projects, insights];
    }
    const filteredInsights = insights.filter(i =>
      filteredCategories.reduce(
        (prev, c) => prev && tags[c].find(t => i.tag_ids.includes(t)),
        true
      )
    );
    return [value, projects, filteredInsights];
  }

  private sortInsights([value, insights]: [string, Insight[]]) {
    return insights.sort((a, b) => {
      const distance =
        levenshtein.get(a.title.en, value) - levenshtein.get(b.title.en, value);
      const lengthDiff = a.title.en.length - b.title.en.length;
      if (a.title.en.toLowerCase().includes(value.toLowerCase())) {
        return -1;
      } else if (b.title.en.toLowerCase().includes(value.toLowerCase())) {
        return 1;
      }
      return distance - lengthDiff;
    });
  }
}
