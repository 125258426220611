import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'kxl-insights-list',
  templateUrl: './insights-list.component.html',
  styleUrls: ['./insights-list.component.scss']
})
export class InsightsListComponent implements OnInit {
  @Input()
  insights;
  @Output()
  open = new EventEmitter();
  columns = 5;

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit() {
    this.breakpointObserver
      .observe([Breakpoints.Large])
      .pipe(filter(event => event.matches))
      .subscribe(() => (this.columns = 5));
    this.breakpointObserver
      .observe([Breakpoints.Medium])
      .pipe(filter(event => event.matches))
      .subscribe(() => (this.columns = 3));
    this.breakpointObserver
      .observe([Breakpoints.Small])
      .pipe(filter(event => event.matches))
      .subscribe(() => (this.columns = 2));
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(filter(event => event.matches))
      .subscribe(() => (this.columns = 1));
  }
}
