import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { TagCategory } from './tag-category.model';

export interface TagCategoriesState extends EntityState<TagCategory> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tagCategories' })
export class TagCategoriesStore extends EntityStore<
  TagCategoriesState,
  TagCategory
> {
  constructor() {
    super();
  }
}
