import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'kxl-insight-dialog',
  templateUrl: './insight-dialog.component.html',
  styleUrls: ['./insight-dialog.component.scss']
})
export class InsightDialogComponent implements OnInit {
  title;

  constructor(
    @Inject(MAT_DIALOG_DATA) public insight: any,
    private san: DomSanitizer
  ) {}

  ngOnInit() {
    this.title = this.san.bypassSecurityTrustHtml(this.insight.title.en);
  }

  safeHTML(text) {
    return this.san.bypassSecurityTrustHtml(text);
  }
}
