import { Injectable } from '@angular/core';
import { TagsStore } from './tags.store';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { TagsQuery } from './tags.query';
import { createTag, Tag } from './tag.model';
import { ID } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class TagsService {
  private tagsCollection: AngularFirestoreCollection;

  constructor(
    private tagsStore: TagsStore,
    private query: TagsQuery,
    private db: AngularFirestore
  ) {
    this.tagsCollection = this.db.collection('tags');
    this.get();
  }

  get() {
    this.tagsCollection.valueChanges().subscribe((tags: Tag[]) => {
      this.tagsStore.set(tags);
    });
  }

  add(name: string, category: ID) {
    const id = this.db.createId();
    const tag = createTag(name, category);
    tag.id = id;
    this.tagsCollection
      .doc(id)
      .set(tag)
      .then(() => {
        this.tagsStore.add(tag);
      });
  }

  update(id, data: Partial<Tag>) {
    const tag = this.query.getEntity(id);
    const update = {
      ...tag,
      ...data
    };
    this.tagsCollection
      .doc(id)
      .set(update)
      .then(() => {
        this.tagsStore.update(id, update);
      });
  }

  remove(id) {
    this.tagsCollection
      .doc(id)
      .delete()
      .then(() => {
        this.tagsStore.remove(id);
      });
  }
}
