import { Injectable } from '@angular/core';
import { Order, QueryEntity, SelectOptions, SortBy } from '@datorama/akita';
import { InsightsState, InsightsStore } from './insights.store';
import { Insight } from './insight.model';
import { TagsQuery } from '../tags';
import { ProjectsQuery } from '../projects';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { TagCategoriesQuery } from '../tagCategories';

@Injectable({ providedIn: 'root' })
export class InsightsQuery extends QueryEntity<InsightsState, Insight> {
  constructor(
    protected store: InsightsStore,
    private categoriesQuery: TagCategoriesQuery,
    private tagsQuery: TagsQuery,
    private projectsQuery: ProjectsQuery
  ) {
    super(store);
  }

  public selectAllWithData(options: {
    filterBy?: SelectOptions<Insight>['filterBy'];
    limitTo?: number;
    sortBy?: SortBy<Insight>;
    sortByOrder?: Order;
  }): Observable<Insight[]> {
    return combineLatest(
      this.selectAll(options),
      this.projectsQuery.selectAll(),
      this.tagsQuery.selectAll(),
      this.categoriesQuery.selectAll()
    ).pipe(
      map(([insights, projects, tags, categories]) =>
        insights.map(insight => {
          return {
            ...insight,
            project: projects.find(p => p.id === insight.project_id),
            tags: insight.tag_ids.map(tag_id =>
              tags
                .map(tag => {
                  return {
                    ...tag,
                    category: categories.find(c => c.id === tag.category_id)
                  };
                })
                .find(t => t.id === tag_id)
            )
          };
        })
      )
    );
  }

  public selectAllLoading() {
    return combineLatest(
      this.selectLoading(),
      this.tagsQuery.selectLoading(),
      this.categoriesQuery.selectLoading(),
      this.projectsQuery.selectLoading()
    ).pipe(
      map((loaders: boolean[]) =>
        loaders.reduce((pre, curr) => pre || curr, false)
      )
    );
  }
}
