import { Injectable } from '@angular/core';
import { Order, QueryEntity, SelectOptions, SortBy } from '@datorama/akita';
import { TagCategoriesState, TagCategoriesStore } from './tag-categories.store';
import { TagCategory } from './tag-category.model';
import { combineLatest, Observable } from 'rxjs';
import { TagsQuery } from '../tags';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TagCategoriesQuery extends QueryEntity<
  TagCategoriesState,
  TagCategory
> {
  constructor(
    protected store: TagCategoriesStore,
    private tagsQuery: TagsQuery
  ) {
    super(store);
  }

  public selectAllWithTags(
    options: {
      filterBy?: SelectOptions<TagCategory>['filterBy'];
      limitTo?: number;
      sortBy?: SortBy<TagCategory>;
      sortByOrder?: Order;
    } = {}
  ): Observable<TagCategory[]> {
    return combineLatest(
      this.selectAll(options),
      this.tagsQuery.selectAll()
    ).pipe(
      map(([categories, tags]) => {
        return categories.map(category => {
          return {
            ...category,
            tags: tags.filter(tag => tag.category_id === category.id)
          };
        });
      })
    );
  }
}
