import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TagsState, TagsStore } from './tags.store';
import { Tag } from './tag.model';

@Injectable({ providedIn: 'root' })
export class TagsQuery extends QueryEntity<TagsState, Tag> {
  constructor(protected store: TagsStore) {
    super(store);
  }
}
