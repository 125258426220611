import { ID } from '@datorama/akita';
import {
  createTranslatedString,
  TranslatedString
} from '../../helpers/translatedString';
import { Tag } from '../tags';

export interface TagCategory {
  id: ID;
  name: TranslatedString;
  tags?: Tag[];
}

export function createTagCategory(name: string) {
  return { name: createTranslatedString(name) } as TagCategory;
}
