import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CreateProjectComponent } from '../create-project/create-project.component';
import {
  Project,
  ProjectsQuery,
  ProjectsService
} from '../../../shared/state/projects';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'kxl-list-projects',
  templateUrl: './list-projects.component.html',
  styleUrls: ['./list-projects.component.scss']
})
export class ListProjectsComponent implements OnInit {
  projects$: Observable<Project[]>;

  constructor(
    private dialog: MatDialog,
    private projectsService: ProjectsService,
    private router: Router,
    private projectsQuery: ProjectsQuery
  ) {}

  ngOnInit() {
    this.projects$ = this.projectsQuery.selectAll();
  }

  openDialog() {
    this.dialog
      .open(CreateProjectComponent)
      .afterClosed()
      .subscribe(data => {
        this.projectsService.add(data.form);
        if (data.addInsight) {
          this.router.navigateByUrl('/manage/insights/new');
        }
      });
  }
}
