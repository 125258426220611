import {
  Component,
  forwardRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const KXL_EDITABLE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EditableComponent),
  multi: true
};

@Component({
  providers: [KXL_EDITABLE_VALUE_ACCESSOR],
  selector: 'kxl-editable',
  templateUrl: './editable.component.html',
  styleUrls: ['./editable.component.scss']
})
export class EditableComponent implements OnInit, ControlValueAccessor {
  @ViewChild('editable')
  textarea;
  @Input()
  allowedTag = '';
  onChange;
  onTouched;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    const div = this.textarea.nativeElement;
    const action = isDisabled ? 'addClass' : 'removeClass';
    this.renderer[action](div, 'disabled');
  }

  writeValue(value: any): void {
    const div = this.textarea.nativeElement;
    this.renderer.setProperty(div, 'textContent', value);
  }

  change($event) {
    // Angular does not know that the value has changed
    // from our component, so we need to update her with the new value.
    const value = $event.target.innerHTML
      .replace(
        new RegExp(
          `<(?!${this.allowedTag}>)(?!/${this.allowedTag}>)(([^>]*)>)`,
          'gi'
        ),
        ' '
      )
      .replace(new RegExp('\\s+', 'gi'), ' ')
      .trim();
    this.onChange(value);
    this.onTouched(value);
  }

  preventUnwanted(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      event.preventDefault();
    }
  }
}
