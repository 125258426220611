import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListInsightsComponent } from './list-insights/list-insights.component';
import { CreateInsightComponent } from './create-insight/create-insight.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { EditableComponent } from './create-insight/editable/editable.component';

const routes: Routes = [];

@NgModule({
  imports: [CommonModule, SharedModule, RouterModule.forChild(routes)],
  declarations: [
    ListInsightsComponent,
    CreateInsightComponent,
    EditableComponent
  ]
})
export class InsightsModule {}
