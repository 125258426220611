import { Injectable } from '@angular/core';
import { InsightsStore } from './insights.store';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { createInsight, Insight } from './insight.model';

@Injectable({ providedIn: 'root' })
export class InsightsService {
  private insightsCollection: AngularFirestoreCollection;

  constructor(
    private insightsStore: InsightsStore,
    private db: AngularFirestore
  ) {
    this.insightsCollection = this.db.collection('insights');
    this.get();
  }

  get() {
    this.insightsCollection.valueChanges().subscribe((insights: Insight[]) => {
      this.insightsStore.set(insights);
    });
  }

  add(insight) {
    const id = this.db.createId();
    insight.id = id;
    insight = createInsight(insight);
    console.log(JSON.stringify(insight));
    this.insightsCollection
      .doc(id)
      .set(insight)
      .then(() => {
        this.insightsStore.add(insight);
      });
  }
}
