import { ID } from '@datorama/akita';
import {
  createTranslatedString,
  TranslatedString
} from '../../helpers/translatedString';

export interface Tag {
  id: ID;
  name: TranslatedString;
  category_id: ID;
}

export function createTag(name: string, category_id: ID) {
  return { name: createTranslatedString(name), category_id } as Tag;
}
