import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatProgressBarModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatTableModule,
  MatToolbarModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { IconToastComponent } from './icon-toast/icon-toast.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { LayoutModule } from '@angular/cdk/layout';

const matModules = [
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatDialogModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatSelectModule,
  MatSidenavModule,
  MatTableModule,
  MatRippleModule,
  MatToolbarModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatExpansionModule
];

@NgModule({
  imports: [
    CommonModule,
    matModules,
    FlexLayoutModule,
    ReactiveFormsModule,
    LayoutModule
  ],
  declarations: [IconToastComponent, ConfirmationDialogComponent],
  entryComponents: [IconToastComponent, ConfirmationDialogComponent],
  exports: [matModules, FlexLayoutModule, ReactiveFormsModule, LayoutModule],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } }
  ]
})
export class SharedModule {}
