import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { InsightsModule } from './insights/insights.module';
import { ProjectsModule } from './projects/projects.module';
import { TagsModule } from './tags/tags.module';
import { ManagementComponent } from './management.component';
import { ListInsightsComponent } from './insights/list-insights/list-insights.component';
import { CreateInsightComponent } from './insights/create-insight/create-insight.component';
import { SharedModule } from '../shared/shared.module';
import { ListProjectsComponent } from './projects/list-projects/list-projects.component';
import { ManageTagsComponent } from './tags/manage-tags/manage-tags.component';

const routes: Routes = [
  {
    path: 'manage',
    component: ManagementComponent,
    children: [
      { path: 'insights', component: ListInsightsComponent },
      { path: 'insights/new', component: CreateInsightComponent },
      { path: 'projects', component: ListProjectsComponent },
      { path: 'tags', component: ManageTagsComponent }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    InsightsModule,
    ProjectsModule,
    TagsModule
  ],
  declarations: [ManagementComponent]
})
export class ManagementModule {}
