import { Injectable } from '@angular/core';
import { ProjectsStore } from './projects.store';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { createProject, Project } from './project.model';

@Injectable({ providedIn: 'root' })
export class ProjectsService {
  private projectsCollection: AngularFirestoreCollection;

  constructor(
    private projectsStore: ProjectsStore,
    private db: AngularFirestore
  ) {
    this.projectsCollection = this.db.collection('projects');
    this.get();
  }

  get() {
    this.projectsCollection.valueChanges().subscribe((projects: Project[]) => {
      this.projectsStore.set(projects);
    });
  }

  add(project) {
    const id = this.db.createId();
    project = createProject(project);
    project.id = id;
    this.projectsCollection
      .doc(id)
      .set(project)
      .then(() => {
        this.projectsStore.add(project);
      });
  }
}
