import { ID } from '@datorama/akita';
import {
  createTranslatedString,
  TranslatedString
} from '../../helpers/translatedString';

export interface Project {
  id: ID;
  title: TranslatedString;
  description: TranslatedString;
}

export function createProject(data: { title: string; description: string }) {
  return {
    title: createTranslatedString(data.title),
    description: createTranslatedString(data.description)
  } as Project;
}
