import { Component, OnInit } from '@angular/core';
import {
  TagCategoriesQuery,
  TagCategoriesService,
  TagCategory
} from '../../../shared/state/tagCategories';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { Observable } from 'rxjs';
import { ID } from '@datorama/akita';
import { TagsQuery, TagsService } from '../../../shared/state/tags';

@Component({
  selector: 'kxl-manage-tags',
  templateUrl: './manage-tags.component.html',
  styleUrls: ['./manage-tags.component.scss']
})
export class ManageTagsComponent implements OnInit {
  public categories$: Observable<TagCategory[]>;
  public editId: ID;
  public categoryForm: FormGroup;

  constructor(
    private categoriesQuery: TagCategoriesQuery,
    private tagsQuery: TagsQuery,
    private categoriesService: TagCategoriesService,
    private tagsService: TagsService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {}

  get tags() {
    return this.categoryForm.get('tags') as FormArray;
  }

  ngOnInit() {
    this.categories$ = this.categoriesQuery.selectAllWithTags();
  }

  addNewCategory() {
    this.categoriesService.add();
  }

  setEditCategory(category) {
    this.categoryForm = this.fb.group({
      name: this.fb.group({
        en: ['', Validators.required]
      }),
      tags: this.fb.array(
        category.tags.map(() =>
          this.fb.group({
            name: this.fb.group({
              en: ['', Validators.required]
            }),
            id: ['']
          })
        )
      )
    });
    this.categoryForm.patchValue(category);
    this.editId = category.id;
  }

  addTag() {
    this.tags.push(
      this.fb.group({
        name: this.fb.group({
          en: ['', Validators.required]
        })
      })
    );
  }

  removeTag(i) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: 'Watch out!',
          text:
            'Deleting this Tag will also remove it from any Insight that is currently assigned.'
        }
      })
      .afterClosed()
      .subscribe(proceed => {
        if (proceed) {
          this.tags.removeAt(i);
        }
      });
  }

  cancelEdit() {
    this.editId = undefined;
  }

  updateCategory() {
    const value = this.categoryForm.value;
    const originalTags = this.tagsQuery.getAll({
      filterBy: entity => entity.category_id === this.editId
    });
    const newTags = value.tags.filter(tag => !tag.id);
    const deletedTags = originalTags.filter(
      tag => !value.tags.find(t => t.id === tag.id)
    );
    const updatedTags = value.tags.filter(tag => tag.id);
    newTags.forEach(tag => this.tagsService.add(tag.name.en, this.editId));
    updatedTags.forEach(tag => this.tagsService.update(tag.id, tag));
    deletedTags.forEach(tag => this.tagsService.remove(tag.id));
    this.categoriesService.update(this.editId, { name: value.name });
    this.cancelEdit();
  }
}
