import { ID } from '@datorama/akita';
import {
  createTranslatedString,
  TranslatedString
} from '../../helpers/translatedString';
import { Tag } from '../tags';
import { Project } from '../projects';

export interface Insight {
  id: ID;
  title: TranslatedString;
  description: TranslatedString;
  quotes: TranslatedString[];
  tag_ids: ID[];
  tags?: Tag[];
  project_id: ID;
  project?: Project;
}

export function createInsight({
  id,
  title,
  description,
  quotes = [],
  tag_ids = [],
  project_id
}: {
  id: ID;
  title: string;
  description: string;
  quotes?: string[];
  tag_ids?: ID[];
  project_id: ID;
}): Insight {
  return {
    id,
    title: createTranslatedString(title),
    description: createTranslatedString(description),
    quotes: quotes.map(q => createTranslatedString(q)),
    tag_ids,
    project_id
  };
}
