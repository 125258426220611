import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { InsightsService } from '../../../shared/state/insights/index';
import { MatAutocompleteSelectedEvent, MatSnackBar } from '@angular/material';
import { IconToastComponent } from '../../../shared/icon-toast/icon-toast.component';
import {
  Project,
  ProjectsQuery,
  ProjectsService
} from '../../../shared/state/projects';
import {
  TagCategoriesQuery,
  TagCategoriesService,
  TagCategory
} from '../../../shared/state/tagCategories';
import { Observable } from 'rxjs';
import { TagsService } from '../../../shared/state/tags';
import { map, startWith, switchMap } from 'rxjs/operators';
import { ID } from '@datorama/akita';

@Component({
  selector: 'kxl-create-insight',
  templateUrl: './create-insight.component.html',
  styleUrls: ['./create-insight.component.scss']
})
export class CreateInsightComponent implements OnInit {
  public insightForm: FormGroup;
  public projects$: Observable<Project[]>;
  public categories$: Observable<TagCategory[]>;
  removable = true;
  tagCtrl = new FormControl();
  filteredCategories: Observable<TagCategory[]>;
  tags: { id: ID; display: string }[] = [];

  @ViewChild('tagInput')
  tagInput: ElementRef<HTMLInputElement>;

  constructor(
    private fb: FormBuilder,
    private insightsService: InsightsService,
    private projectsService: ProjectsService,
    private categoriesService: TagCategoriesService,
    private tagsService: TagsService,
    private snackBar: MatSnackBar,
    private projectsQuery: ProjectsQuery,
    private categoriesQuery: TagCategoriesQuery
  ) {}

  get quotes() {
    return this.insightForm.get('quotes') as FormArray;
  }

  ngOnInit() {
    this.filteredCategories = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      switchMap((fruit: string | null) =>
        this.categories$.pipe(map(this._filter(fruit)))
      )
    );
    this.projects$ = this.projectsQuery.selectAll();
    this.categories$ = this.categoriesQuery.selectAllWithTags();
    this.initForm();
  }

  initForm() {
    this.tags = [];
    // this.insightForm.reset();
    this.insightForm = this.fb.group({
      title: ['Enter your Insight title here', Validators.required],
      description: [
        'Enter the description for your new insight here',
        Validators.required
      ],
      project: [undefined, Validators.required],
      quotes: this.fb.array([
        this.fb.control(
          'Enter the first quote that validates your insight here'
        )
      ])
    });
  }

  remove(tag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push({ id: event.option.value, display: event.option.viewValue });
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  underline() {
    document.execCommand('underline');
  }

  bolden() {
    document.execCommand('bold');
  }

  removeFormat() {
    document.execCommand('removeFormat');
  }

  addQuote() {
    this.quotes.push(
      this.fb.control('Enter another quote for your insight here')
    );
  }

  removeQuote(index) {
    this.quotes.removeAt(index);
  }

  submitInsight() {
    if (this.insightForm.invalid) {
      return;
    }
    const data = this.insightForm.value;
    data.quotes = data.quotes.filter(quote => quote.trim());
    data.tag_ids = this.tags.map(t => t.id);
    data.project_id = data.project.id;
    this.insightsService.add(this.insightForm.value);
    this.snackBar.openFromComponent(IconToastComponent, {
      data: { text: 'Your insight was saved!', icon: 'green-check' }
    });
    this.initForm();
  }

  private _filter(value: string | null) {
    return (categories: TagCategory[]) => {
      const cleaned = categories.map(cat => {
        return {
          ...cat,
          tags: cat.tags.filter(
            tag =>
              !this.tags.find(
                t => t.display.toLowerCase() === tag.name.en.toLowerCase()
              )
          )
        };
      });
      if (value === null) {
        return cleaned;
      }
      const filterValue = value.toLowerCase();
      return cleaned
        .filter(cat =>
          cat.tags.find(tag => tag.name.en.toLowerCase().includes(filterValue))
        )
        .map(cat => {
          return {
            ...cat,
            tags: cat.tags.filter(tag =>
              tag.name.en.toLowerCase().includes(filterValue)
            )
          };
        });
    };
    // return this.allFruits.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
}
