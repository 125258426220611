import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'kxl-insight-card',
  templateUrl: './insight-card.component.html',
  styleUrls: ['./insight-card.component.scss']
})
export class InsightCardComponent implements OnInit {
  @Input()
  insight;
  title;

  constructor(private san: DomSanitizer) {}

  ngOnInit() {
    this.title = this.san.bypassSecurityTrustHtml(this.insight.title.en);
  }
}
